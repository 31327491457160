.footer-container {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-pimary-900);
}
.footer-container1 {
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.footer-container2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-text {
  color: var(--dl-color-secondary-400);
  margin-top: var(--dl-space-space-unit);
  font-weight: 600;
}
.footer-text1 {
  color: var(--dl-color-secondary-500);
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-container3 {
  display: flex;
  margin-top: var(--dl-space-space-unitandhalf);
  align-items: flex-start;
  flex-direction: row;
}
.footer-container4 {
  flex: 0 0 auto;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.footer-link {
  display: contents;
}
.footer-icon {
  width: 24px;
  height: 24px;
  text-decoration: none;
}
.footer-container5 {
  flex: 0 0 auto;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.footer-link1 {
  display: contents;
}
.footer-icon2 {
  width: 16px;
  height: 16px;
  text-decoration: none;
}
.footer-container6 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.footer-container7 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-text2 {
  color: var(--dl-color-secondary-400);
  font-weight: 600;
  padding-bottom: var(--dl-space-space-halfunit);
}
.footer-text3 {
  color: var(--dl-color-secondary-500);
  padding-bottom: var(--dl-space-space-halfunit);
}
.footer-text4 {
  color: var(--dl-color-secondary-500);
  padding-bottom: var(--dl-space-space-halfunit);
}
.footer-text5 {
  color: var(--dl-color-secondary-500);
  padding-bottom: var(--dl-space-space-halfunit);
}
.footer-text6 {
  color: var(--dl-color-secondary-500);
}
.footer-container8 {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  margin-top: var(--dl-space-space-tripleunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
  background-color: #e3e8efff;
}
.footer-text7 {
  color: var(--dl-color-secondary-500);
  align-self: center;
}
@media(max-width: 991px) {
  .footer-container1 {
    align-items: center;
    flex-direction: column;
  }
  .footer-container2 {
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .footer-container7 {
    align-items: center;
  }
}
@media(max-width: 767px) {
  .footer-text1 {
    text-align: center;
  }
  .footer-container6 {
    align-items: center;
    flex-direction: column;
  }
  .footer-container7 {
    align-items: center;
  }
}
