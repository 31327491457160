.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
}
.home-hero {
  flex: 0 0 auto;
  width: 100%;
  height: 80vh;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url('https://images.unsplash.com/photo-1488229297570-58520851e868?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDJ8fGNsb3VkJTIwc2VydmVyfGVufDB8fHx8MTcxMjI1OTk1OHww&ixlib=rb-4.0.3&w=1500');
}
.home-bg {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0.7;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-black);
}
.home-link {
  display: contents;
}
.home-component {
  text-decoration: none;
}
.home-container01 {
  top: auto;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-container02 {
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  justify-content: center;
}
.home-text {
  color: var(--dl-color-gray-white);
  text-align: center;
}
.home-text01 {
  color: var(--dl-color-gray-900);
  max-width: 600px;
  text-align: center;
}
.home-image {
  top: auto;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: -1px;
  position: absolute;
  object-fit: cover;
}
.home-image1 {
  left: auto;
  right: 0px;
  width: 100%;
  bottom: 0px;
  display: none;
  position: absolute;
  object-fit: cover;
}
.home-section1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: var(--dl-color-pimary-900);
}
.home-container03 {
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: center;
  flex-direction: column;
}
.home-container04 {
  width: 66%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.home-text02 {
  color: var(--dl-color-secondary-400);
  text-align: center;
}
.home-text03 {
  color: #2f0f0f;
  text-align: center;
  margin-bottom: 0.25rem;
}
.home-cards-container {
  display: flex;
  margin-top: var(--dl-space-space-tripleunit);
  align-items: flex-start;
  padding-top: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.home-card1 {
  flex: 0 0 auto;
  width: 30%;
  height: 592px;
  display: flex;
  padding: var(--dl-space-space-unit);
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius50);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-container05 {
  flex: 0 0 auto;
  width: 150px;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 140px;
  display: flex;
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url('https://images.unsplash.com/photo-1573164713988-8665fc963095?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDQ0fHxkZXZ8ZW58MHx8fHwxNzEyMjYwNDM4fDA&ixlib=rb-4.0.3&w=200');
}
.home-text04 {
  color: var(--dl-color-secondary-100);
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-family: Open Sans;
}
.home-text05 {
  color: var(--dl-color-secondary-700);
  font-size: 1rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  font-family: "Open Sans";
  line-height: 1.625rem;
}
.home-card2 {
  flex: 0 0 auto;
  width: 30%;
  display: flex;
  padding: var(--dl-space-space-unit);
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius50);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-container06 {
  width: 150px;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 140px;
  display: flex;
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url('https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDJ8fGRhc2hib2FyZHxlbnwwfHx8fDE3MTIyNjIxMDZ8MA&ixlib=rb-4.0.3&w=200');
}
.home-text06 {
  color: var(--dl-color-secondary-100);
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-family: Open Sans;
}
.home-text07 {
  color: var(--dl-color-secondary-700);
  font-size: 1rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  font-family: Open Sans;
  line-height: 1.625rem;
}
.home-card3 {
  flex: 0 0 auto;
  width: 30%;
  height: 590px;
  display: flex;
  padding: var(--dl-space-space-unit);
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius50);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-container07 {
  flex: 0 0 auto;
  width: 150px;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 140px;
  display: flex;
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url('https://images.unsplash.com/photo-1554224155-8d04cb21cd6c?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDN8fGZpbmFuY2Vpcm98ZW58MHx8fHwxNzEyMjYwNjk0fDA&ixlib=rb-4.0.3&w=200');
}
.home-text08 {
  color: var(--dl-color-secondary-100);
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-family: Open Sans;
}
.home-text09 {
  color: var(--dl-color-secondary-700);
  font-size: 1rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  font-family: "Open Sans";
  line-height: 1.625rem;
}
.home-work-with-us {
  display: flex;
  margin-top: var(--dl-space-space-tripleunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: row;
}
.home-container08 {
  flex: 0 0 auto;
  width: 41%;
  display: flex;
  position: relative;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.home-text10 {
  color: var(--dl-color-secondary-400);
  align-self: center;
  text-align: center;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text14 {
  color: var(--dl-color-secondary-500);
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}
.home-text15 {
  color: var(--dl-color-secondary-400);
}
.home-container09 {
  flex: 0 0 auto;
  width: 33%;
  display: flex;
  position: relative;
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
  border-radius: var(--dl-radius-radius-radius50);
  flex-direction: column;
  background-color: #09A6E9;
}
.home-container10 {
  width: 100%;
  height: 269px;
  display: flex;
  align-items: flex-end;
  border-radius: 0px;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-image: url('https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80');
  background-position: center;
  border-top-left-radius: var(--dl-radius-radius-radius50);
  border-top-right-radius: var(--dl-radius-radius-radius50);
}
.home-image2 {
  top: auto;
  left: auto;
  width: 100%;
  bottom: 0px;
  object-fit: cover;
  margin-bottom: -1px;
}
.home-container11 {
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.home-text16 {
  font-size: 1.25rem;
}
.home-text17 {
  color: #f2f5f9;
}
.home-text19 {
  color: rgb(242, 245, 249);
  margin-top: var(--dl-space-space-unit);
}
.home-image3 {
  top: auto;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: -1px;
  position: absolute;
  object-fit: cover;
}
.home-section2 {
  width: 100%;
  height: 991px;
  display: flex;
  align-self: center;
  align-items: center;
  padding-top: 8px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1506869640319-fe1a24fd76dc?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDY1fHxlcXVpcGV8ZW58MHx8fHwxNzEyMjYwODUyfDA&ixlib=rb-4.0.3&w=1500");
}
.home-container12 {
  width: 66%;
  height: 182px;
  display: flex;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-text20 {
  color: var(--dl-color-secondary-400);
  height: 60px;
  text-align: center;
}
.home-text21 {
  color: #245aa6;
  text-align: center;
  margin-bottom: 0.25rem;
}
.home-team {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.home-container13 {
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: flex-start;
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.home-container14 {
  width: 50%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.home-container15 {
  width: 50%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.home-container16 {
  width: 50%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.home-container17 {
  width: 50%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.home-section3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: var(--dl-color-secondary-100);
}
.home-container18 {
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.home-contact {
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.home-text22 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}
.home-text23 {
  color: rgba(255, 255, 255, 0.75);
  width: 80%;
  text-align: center;
  font-weight: 300;
}
.home-form {
  flex: 0 0 auto;
  width: 80%;
  display: flex;
  padding: var(--dl-space-space-twoandhalf);
  max-width: 700px;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius50);
  flex-direction: column;
  background-color: var(--dl-color-pimary-800);
}
.home-text24 {
  color: var(--dl-color-secondary-400);
}
.home-text25 {
  color: rgba(100,116,139,1);
}
.home-text29 {
  color: rgba(100,116,139,1);
  margin-top: var(--dl-space-space-halfunit);
  margin-left: 0.25rem;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-textinput {
  color: rgba(148, 163, 184, 1);
  width: 100%;
  outline: 0;
  padding: var(--dl-space-space-triplequarter);
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1),0 1px 2px 0 rgba(0,0,0,0.06);;
  transition: 0.3s;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
}
.home-textinput:focus {
  color: var(--dl-color-secondary-500);
  box-shadow: 0 0 0 2px#4AA4E3;
}
.home-text30 {
  color: rgba(100,116,139,1);
  margin-top: var(--dl-space-space-halfunit);
  margin-left: 0.25rem;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-textinput1 {
  color: rgba(148, 163, 184, 1);
  width: 100%;
  outline: 0;
  padding: var(--dl-space-space-triplequarter);
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1),0 1px 2px 0 rgba(0,0,0,0.06);;
  transition: 0.3s;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
}
.home-textinput1:focus {
  color: var(--dl-color-secondary-500);
  box-shadow: 0 0 0 2px#4AA4E3;
}
.home-container19 {
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.home-image4 {
  top: auto;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: -1px;
  position: absolute;
  object-fit: cover;
}
@media(max-width: 991px) {
  .home-cards-container {
    align-items: center;
    flex-direction: column;
  }
  .home-card1 {
    width: 90%;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-card2 {
    width: 90%;
    margin-bottom: var(--dl-space-space-fiveunits);
  }
  .home-card3 {
    width: 90%;
  }
  .home-section2 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .home-section3 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .home-container18 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .home-section1 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .home-container04 {
    width: 100%;
  }
  .home-work-with-us {
    margin-top: var(--dl-space-space-tripleunit);
    flex-direction: column;
  }
  .home-container08 {
    width: 100%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-container09 {
    width: 100%;
  }
  .home-container12 {
    width: auto;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-container13 {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .home-container14 {
    width: 50%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-container15 {
    width: 50%;
    padding-bottom: var(--dl-space-space-tripleunit);
  }
  .home-container16 {
    width: 50%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-container17 {
    width: 50%;
  }
  .home-form {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .home-container02 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .home-card1 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-text05 {
    text-align: left;
  }
  .home-card2 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-text07 {
    text-align: left;
  }
  .home-text09 {
    text-align: left;
  }
  .home-container13 {
    align-items: center;
    flex-direction: column;
  }
  .home-text22 {
    text-align: center;
  }
  .home-text24 {
    text-align: left;
  }
}
